import React, { useEffect, useState } from 'react'

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import EditorRenderer from '../../components/editor-renderer';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { clearBlogs, getBySlug } from 'store/blogs/blogs.slice';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export default function BlogDetail() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { blog = null, isLoading = true, errorCode = null } = useSelector((state: RootState) => state.blogs);
    const { slug } = useParams<{ slug: string }>();
    const [isReady, setIsReady] = useState(false); // State to track element existence

    useEffect(() => {
        const element = document.getElementById('editorjs');
        if (element) {
            setIsReady(true);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (slug) {
                await dispatch(getBySlug({ slug }));
                setIsReady(true);
            }
        };
        fetchData();

        return () => {
            dispatch(clearBlogs());
        };
    }, [dispatch, slug]);

    useEffect(() => {
        if (errorCode) {
            navigate('/404', { replace: true });
        }
    }, [errorCode, navigate]);

    const currentUrl = `${window.location.origin}/blog/${blog?.slug}`;

    //// @debug
    // console.log("blogDetail blog, isLoading, errorCode", blog, isLoading, errorCode );
  
    return (
    <>
    <Navbar navlight={true} gradient={true}/>
    <section className="section mt-5">
      <div className="container">
        <div className="row mt-5 mt-lg-0 justify-content-center">
            {isLoading ? (
                <div className="col">
                    <div className="d-flex justify-content-center">
                        <Spinner />
                    </div>
                </div>
            ): blog && (
                <>
                <Helmet>
                    <title>{blog.title} - {process.env.REACT_APP_NAME}</title>
                    <meta content={blog.title +' | '+ process.env.REACT_APP_NAME} property="og:title"/>
                    <meta content="article" property="og:type"/>
                    <meta content={blog.image_permalink} property="og:image"/>
                    <meta content={currentUrl} property="og:url"/>
                    <meta content={blog.short_description} property="og:description"/>

                    <meta content={blog.image_permalink} name="twitter:card" />
                    <meta content={blog.title} name="twitter:title"/>
                    <meta content={'Created by ' + process.env.REACT_APP_NAME} name="twitter:author"/>
                    <meta content={blog.image_permalink} name="twitter:image"/>
                    <meta content={blog.title} name="twitter:image:alt"/>
                    <meta content={currentUrl + "/?utm_medium=organic&amp;utm_source=twitter&amp;utm_campaign=demo"} name="twitter:url"/>
                    <meta content={'View on ' + process.env.REACT_APP_NAME} name="twitter:cta"/>
                    <link href={currentUrl} rel="canonical"/>
                    <meta content={blog.short_description} name="description"/>
                
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'WebSite',
                            name: process.env.REACT_APP_NAME,
                            url: window.location.origin
                        })}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Organization',
                            name: process.env.REACT_APP_NAME,
                            url: window.location.origin,
                            logo: window.location.origin + '/assets/images/logo-light.webp',
                        })}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Article',
                            name: blog.title,
                            description: blog.short_description,
                            author: blog.author,
                            image: blog.image_permalink,
                            url: currentUrl,
                        })}
                    </script>
                </Helmet>

                <div className="col-12 text-center">
                    <div className="title-heading">
                        <h4 className="heading sub-heading fw-bold mb-3">{blog.title}</h4>
                        <p className="text-muted-custom fs-5 mb-0">{blog.short_description}</p>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div id="editorjs"></div>
                    {isReady && <EditorRenderer id='editorjs' data={blog.content} />}
                </div>
                <div className="col-12 mt-5">
                    <div className="text-center">
                        <Link to={`/blog`} className="btn btn-dark rounded-md">{t('blog.labels.back')}</Link>
                    </div>
                </div>
                </>
            )}
        </div>
      </div>
    </section>
    <Footer/>
    </>
  )
}
